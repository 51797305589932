export interface IPoetry {
  title: string
  author: string
  content: string[]
  type: string
}

export function getRandomPoetry() {
  let index = Math.floor(Math.random() * cn_poetry.length)
  return cn_poetry[index]
}

const cn_poetry = [
  {
    title: '南园十三首(其五)',
    author: '李贺',
    content: ['男儿何不带吴钩', '收取关山五十州'],
    type: '唐',
  },
  {
    title: '老将行',
    author: '王维',
    content: ['一身转战三千里', '一剑曾当百万师'],
    type: '唐',
  },
  {
    title: '献钱尚父',
    author: '贯休',
    content: ['满堂花醉三千客', '一剑霜寒十四州'],
    type: '唐',
  },
  {
    title: '梅岭三章(其一)',
    author: '陈毅',
    content: ['此去泉台招旧部', '旌旗十万斩阎罗'],
    type: '近',
  },
  {
    title: '戏为六绝句(其二)',
    author: '杜甫',
    content: ['尔曹身与名俱灭', '不废江河万古流'],
    type: '唐',
  },
  {
    title: '题三十计小象',
    author: '吴庆坻',
    content: ['须知少日拏云志', '曾许人间第一流'],
    type: '清',
  },
  {
    title: '登北固楼',
    author: '黄仲则',
    content: ['不尽狂澜走沧海', '一拳天与压潮头'],
    type: '清',
  },
  {
    title: '水调歌头·和马叔度游月波楼',
    author: '辛弃疾',
    content: ['鲸饮未吞海', '剑气已横秋'],
    type: '宋',
  },
  {
    title: '满江红·和郭沫若同志',
    author: '毛泽东',
    content: ['一万年太久', '只争朝夕'],
    type: '近',
  },
  {
    title: '七绝·观潮',
    author: '毛泽东',
    content: ['人山纷赞阵容阔', '铁马从容杀敌回'],
    type: '近',
  },
]
